<template></template>

<script>
import ROSLIB from "roslib";
export default {
  props: {
    url: {
      default: null
    }
  },
  data() {
    return {
      ros: null
    };
  },
  created() {
    this.init();
  },
  watch: {
    url: function() {
      this.init();
    },
    ros: function() {
      this.$emit("getRosObject", this.ros);
    },
    "ros.isConnected": function() {
      this.$emit("getRosObject", this.ros);
    }
  },
  methods: {
    init() {
      if (this.ros) {
        this.ros.close();
      }

      if (!this.url) {
        console.log("未接続");
        return;
      }

      let url = "wss://" + this.url + ":9090";
      // 開発環境ではHTTP
      if (process.env.NODE_ENV == "development") {
        url = "ws://" + this.url + ":9090";
      }

      // Rosへ接続
      this.ros = new ROSLIB.Ros({
        url: url
      });

      this.ros.on("error", function(error) {
        console.log("state : " + "Error", error);
      });

      this.ros.on("connection", function(error) {
        console.log("state : " + "Connect", error);
      });

      this.ros.on("close", function(error) {
        console.log("state : " + "Close", error);
      });
    },
    createTopic(name) {
      let listener = new ROSLIB.Topic({
        ros: this.ros,
        name: name
      });
      return listener;
    },
    createTopicWithMsgType(name, msgType) {
      let listener = new ROSLIB.Topic({
        ros: this.ros,
        name: name,
        messageType: msgType
      });
      return listener;
    },
    createService(name, serviceType) {
      let client = new ROSLIB.Service({
        ros: this.ros,
        name: name,
        serviceType: serviceType
      });
      return client;
    },
    createActionClient(serverName, actionName) {
      let runClient = new ROSLIB.ActionClient({
        ros: this.ros,
        serverName: serverName,
        actionName: actionName
      });
      return runClient;
    },
    createGoal(runClient, goalMessage) {
      let goal = new ROSLIB.Goal({
        actionClient: runClient,
        goalMessage: goalMessage
      });
      return goal;
    },
    createServiceRequest(offset) {
      let request = new ROSLIB.ServiceRequest({
        offset: offset
      });
      return request;
    },
    createServiceRequestWithXY(offset_x, offset_y) {
      let request = new ROSLIB.ServiceRequest({
        offset_x: offset_x,
        offset_y: offset_y
      });
      return request;
    }
  },
  beforeDestroy() {
    if (this.ros) {
      this.ros.close();
    }
  }
};
</script>
